import getConfig from 'next/config';
import type { AxiosResponse } from 'axios';

import { donedealGateway } from 'api/api-utils/commonApiUtils';

import {
  AreasResponse,
  AreasAutocompleteResponse,
} from 'api/types/areasApiTypes';

const {
  publicRuntimeConfig: { API_CLIENT_TIMEOUT },
} = getConfig();

async function getAreasAutocomplete(
  term: string,
  county: string = '',
): Promise<AxiosResponse<AreasAutocompleteResponse>> {
  const response: AxiosResponse<AreasAutocompleteResponse> =
    await donedealGateway.get(
      `/ddapi/v1/areas/autocomplete?${
        county ? `county=${county}&` : ''
      }query=${term}`,
      {
        timeout: parseInt(API_CLIENT_TIMEOUT),
      },
    );

  return response;
}

async function getAreas(
  county: string,
): Promise<AxiosResponse<AreasResponse[]>> {
  const response: AxiosResponse<AreasResponse[]> = await donedealGateway.get(
    `ddapi/v1/areas/${county}`,
    {
      timeout: parseInt(API_CLIENT_TIMEOUT),
    },
  );

  return response;
}

export const areasApi = {
  getAreas,
  getAreasAutocomplete,
};
