import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useOnUpdateOnly } from 'hooks/UseOnUpdateOnly';
import { useWindowSize } from 'hooks/UseWindowSize';
import { searchApi } from 'api/searchApi';
import { fireToast } from 'helpers/Toasts';
import { media } from 'helpers/breakpoints';
import { Select } from 'components/Toolkit/Inputs/Select';
import { InputButton } from 'components/Toolkit/InputButton/InputButton';
import { Dropdown } from 'components/Toolkit/Dropdown/Dropdown';
import { CustomSelect } from 'components/Toolkit/Inputs/CustomSelect';
import { CustomMultiSelect } from 'components/Toolkit/Inputs/CustomMultiSelect';
import { BottomSheet } from 'components/Toolkit/BottomSheet/BottomSheet';
import { SelectGroup } from 'components/Toolkit/Inputs/SelectGroup';
import { Button } from 'components/Toolkit/Button/Button';
import type {
  ListItem,
  MotorSearchProps,
} from 'components/HomePage/components/MotorSearch/MotorSearch.typed';
import {
  getMakesOptions,
  getPopularMakesOptions,
  getPriceOptions,
  getYearOptions,
  getModelOptions,
} from 'components/HomePage/components/MotorSearch/MotorSearch.helpers';
import { useRangeFilter } from 'components/HomePage/components/MotorSearch/RangeFilter/UseRangeFilter.hook';
import { searchPageApi } from 'api/searchPageApi';
import { rg4js } from 'helpers/raygun';
import { PAGE } from 'helpers/pages';
import { API_CLIENT_TIMEOUT } from 'utils';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 48px);
  gap: ${({ theme }) => theme.spacing.M16};

  ${media.medium} {
    grid-template-rows: repeat(2, 48px);
    grid-template-columns: repeat(2, 1fr);
    gap: ${({ theme }) => theme.spacing.M16};
    margin-bottom: ${({ theme }) => theme.spacing.M16};
  }
`;

const Mobile = styled.div`
  ${media.medium} {
    display: none;
  }
`;

const Container = styled.div`
  display: none;

  ${media.medium} {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
  }
`;

function MotorSearch({
  make,
  priceFrom,
  priceTo,
  yearFrom,
  yearTo,
  onMakeSelect,
  onModelsSelect,
  onYearFromSelect,
  onYearToSelect,
  onPriceFromSelect,
  onPriceToSelect,
  setFilteredSectionCount,
  searchFilters,
}: MotorSearchProps) {
  const { width } = useWindowSize();
  const screenMedium = 40 * 16;
  const defaultPreselect = { value: '', displayName: 'All Models' };
  // Data state
  const [allMakes, setAllMakes] = useState<ListItem[]>([]);
  const [allModels, setAllModels] = useState<ListItem[]>([]);
  const [models, setModels] = useState<ListItem[]>([defaultPreselect]);

  const [priceOptionsFrom, priceOptionsTo] = getPriceOptions(searchFilters);
  const [minPrices, maxPrices, filterMaxPrices, resetMaxPrices] =
    useRangeFilter(priceOptionsFrom, priceOptionsTo);

  const [yearOptionsFrom, yearOptionsTo] = getYearOptions(searchFilters);
  const [minYears, maxYears, filterMaxYears, resetMaxYears] = useRangeFilter(
    yearOptionsFrom,
    yearOptionsTo,
  );

  // Visual state
  const [isMakeBottomSheetOpen, setIsMakeBottomSheetOpen] =
    useState<boolean>(false);
  const [isModelBottomSheetOpen, setIsModelBottomSheetOpen] =
    useState<boolean>(false);
  const [isMakeDropdownOpen, setIsMakeDropdownOpen] = useState<boolean>(false);
  const [makeDisplay, setMakeDisplay] = useState<string>('');
  const [isModelDropdownOpen, setIsModelDropdownOpen] =
    useState<boolean>(false);
  const [modelDisplay, setModelDisplay] = useState<string>('');
  const [modelReset, setModelReset] = useState<boolean>(false);
  const [modelApply, setModelApply] = useState<boolean>(false);

  useEffect(() => {
    onYearFromSelect('placeholder');
    onPriceFromSelect('placeholder');
    onYearToSelect('placeholder');
    onPriceToSelect('placeholder');
    getMakes();
  }, []);

  useOnUpdateOnly(() => {
    setAllModels([]);
    setModels([defaultPreselect]);
    setModelDisplay('');
    if (make !== '') {
      getModels();
      handleSectionCount();
    }
  }, [make]);

  useOnUpdateOnly(() => {
    handleSectionCount();
  }, [yearTo, yearFrom, priceTo, priceFrom, models]);

  useEffect(() => {
    if (priceFrom && priceFrom !== 'placeholder') {
      filterMaxPrices(({ value }) => parseInt(value) >= parseInt(priceFrom));
      if (priceTo !== undefined && parseInt(priceTo) <= parseInt(priceFrom)) {
        onPriceToSelect('placeholder');
      }
    } else {
      resetMaxPrices();
    }
  }, [priceFrom]);

  useEffect(() => {
    if (yearFrom && yearFrom !== 'placeholder') {
      filterMaxYears(({ value }) => parseInt(value) >= parseInt(yearFrom));
      if (yearTo !== undefined && parseInt(yearTo) <= parseInt(yearFrom)) {
        onYearToSelect('placeholder');
      }
    } else {
      resetMaxYears();
    }
  }, [yearFrom]);

  // TODO: This is called 3 times when updating the make - maybe debounce
  async function handleSectionCount() {
    try {
      const {
        data: { pagingCounts },
      } = await searchApi.getMotorCount({
        adType: 'forsale',
        max: 3,
        section: 'cars',
        dependant: [
          {
            parentName: 'make',
            parentValue: make,
            childName: 'model',
            childValues: models.map(({ value }) => value),
          },
        ],
        price_from: priceFrom !== 'placeholder' ? priceFrom : undefined,
        price_to: priceTo !== 'placeholder' ? priceTo : undefined,
        year_from: yearFrom !== 'placeholder' ? yearFrom : undefined,
        year_to: yearTo !== 'placeholder' ? yearTo : undefined,
      });
      setFilteredSectionCount(pagingCounts.displayValue);
    } catch (error) {
      rg4js('send', {
        error: new Error('Error handling handleSectionCount'),
        tags: [PAGE.HOME],
        customData: {
          message: error.message || 'client_error',
        },
      });
    }
  }

  function getMakes() {
    const makesList = getMakesOptions(searchFilters);
    const popularMakesList = getPopularMakesOptions(searchFilters);

    setAllMakes([
      { value: '', displayName: 'All Makes' },
      { value: '', displayName: 'Popular' },
      ...popularMakesList,
      { value: '', displayName: 'All Makes' },
      ...makesList,
    ]);
  }

  async function getModels() {
    try {
      // TODO: Timeout should be handled globally by the API with sane defaults
      // https://distilledsch.tpondemand.com/entity/93785-implement-smarter-fe-timeouts
      const { data: models } = await searchPageApi.getModelByMake(
        'cars',
        make,
        undefined,
        undefined,
        API_CLIENT_TIMEOUT,
      );
      setAllModels(getModelOptions(models));
    } catch (error) {
      rg4js('send', {
        error: new Error('Error handling getModels'),
        tags: [PAGE.HOME],
        customData: {
          message: error.message || 'client_error',
        },
      });
      fireToast({
        text: 'Oops! Something went wrong. Please try again later',
        type: 'ERROR',
      });
    }
  }

  function handleSelectMake(makeObject: ListItem) {
    if (makeObject.value !== make) {
      setModels([]);
      onModelsSelect([]);
      setModelDisplay('');
    }
    setMakeDisplay(`${makeObject.displayName} `);
    onMakeSelect(makeObject.value);
    setIsMakeDropdownOpen(false);
    setIsMakeBottomSheetOpen(false);
    if (width && width < screenMedium) {
      makeObject.value && setIsModelBottomSheetOpen(true);
    }
  }

  function handleSelectModels(modelObject: ListItem[]) {
    if (modelObject.length) {
      setModels(modelObject);
      onModelsSelect(modelObject.map((model) => model.value));
      setModelDisplay(
        `${modelObject[0].displayName}${
          modelObject.length > 1 ? ` (+${modelObject.length - 1})` : ''
        }`,
      );
    }
  }

  function handleResetModelsMobile() {
    onMakeSelect('');
    setMakeDisplay('');
    setModels([]);
    onModelsSelect([]);
    setIsMakeBottomSheetOpen(true);
    setIsModelBottomSheetOpen(false);
  }

  function handleApplyModels(modelObject: ListItem[]) {
    onModelsSelect(modelObject.map((model) => model.value));
    setIsModelDropdownOpen(false);
    setIsModelBottomSheetOpen(false);
  }

  function handleBottomSheetClose() {
    setIsMakeBottomSheetOpen(false);
    setIsModelBottomSheetOpen(false);
  }

  function handleOpenModelsDropdown(shouldOpen: boolean) {
    if (make) {
      setIsModelDropdownOpen(shouldOpen);
    } else {
      setIsMakeDropdownOpen(shouldOpen);
    }
  }

  function handleInputClick() {
    if (make) {
      setIsModelBottomSheetOpen(true);
    } else {
      setIsMakeBottomSheetOpen(true);
    }
  }

  return (
    <Wrapper data-testid="makes-models-dropdown">
      <Mobile>
        <InputButton
          data-testid="makes-models-dropdown"
          onClick={handleInputClick}
          isActive={isMakeBottomSheetOpen}
          value={`${makeDisplay}${
            modelDisplay === 'All Models'
              ? `(${modelDisplay})`
              : `${modelDisplay}`
          }`}
          placeholder="All Makes/Models"
          heightVariant="LARGE"
        />
        <BottomSheet
          headerText={
            isMakeBottomSheetOpen
              ? 'All Makes'
              : `${makeDisplay}${
                  modelDisplay === 'All Models'
                    ? `(${modelDisplay})`
                    : `${modelDisplay}`
                }`
          }
          onApply={handleBottomSheetClose}
          askToClose={handleBottomSheetClose}
          isOpen={isMakeBottomSheetOpen || isModelBottomSheetOpen}
          optionSelected={Boolean(makeDisplay) || Boolean(modelDisplay)}
          resetScrollPosition={isMakeBottomSheetOpen}
          buttonA={
            isModelBottomSheetOpen ? (
              <Button ofType="SECONDARY" onClick={() => setModelReset(true)}>
                Reset
              </Button>
            ) : undefined
          }
          buttonB={
            isModelBottomSheetOpen ? (
              <Button onClick={() => setModelApply(true)}>Apply</Button>
            ) : undefined
          }
        >
          {allMakes.length !== 0 && isMakeBottomSheetOpen && (
            <CustomSelect onSelect={handleSelectMake} listData={allMakes} />
          )}
          {allModels.length !== 0 && isModelBottomSheetOpen && (
            <CustomMultiSelect
              onApply={handleApplyModels}
              onCallback={handleSelectModels}
              onReset={handleResetModelsMobile}
              listData={allModels}
              preselectedOption={models}
              defaultPreselect={allModels[0]}
              callReset={modelReset}
              setCallReset={setModelReset}
              callApply={modelApply}
              setCallApply={setModelApply}
              hasButtons={false}
            />
          )}
        </BottomSheet>
      </Mobile>
      <Container data-testid="makes-dropdown">
        <Dropdown
          expanded={isMakeDropdownOpen}
          setExpanded={setIsMakeDropdownOpen}
          placeholder="All Makes"
          displayedValue={makeDisplay}
          heightVariant="LARGE"
        >
          {allMakes.length !== 0 && (
            <CustomSelect onSelect={handleSelectMake} listData={allMakes} />
          )}
        </Dropdown>
      </Container>
      <Container data-testid="models-dropdown">
        <Dropdown
          expanded={isModelDropdownOpen}
          setExpanded={handleOpenModelsDropdown}
          placeholder="All Models"
          displayedValue={modelDisplay}
          isDisabled={!make}
          heightVariant="LARGE"
        >
          {allModels.length !== 0 && (
            <CustomMultiSelect
              onApply={handleApplyModels}
              onCallback={handleSelectModels}
              listData={allModels}
              preselectedOption={models}
              defaultPreselect={allModels[0]}
            />
          )}
        </Dropdown>
      </Container>
      {/* TODO: Add RangeFilter component that can handle both year and price filters generically */}
      <SelectGroup ratio={['1', '1']}>
        <Select
          id="year_from"
          name="year_from"
          value={yearFrom}
          placeholder={false}
          placeholderAsOption={true}
          options={minYears}
          onChange={onYearFromSelect}
          heightVariant="LARGE"
          willUseSubText={false}
        />
        <Select
          id="year_to"
          name="year_to"
          value={yearTo}
          placeholder={false}
          placeholderAsOption={true}
          options={maxYears}
          onChange={onYearToSelect}
          heightVariant="LARGE"
          willUseSubText={false}
        />
      </SelectGroup>
      <SelectGroup ratio={['1', '1']}>
        <Select
          id="price_from"
          name="price_from"
          value={priceFrom}
          placeholder={false}
          placeholderAsOption={true}
          options={minPrices}
          onChange={onPriceFromSelect}
          heightVariant="LARGE"
          willUseSubText={false}
        />
        <Select
          id="price_to"
          name="price_to"
          value={priceTo}
          placeholder={false}
          placeholderAsOption={true}
          options={maxPrices}
          onChange={onPriceToSelect}
          heightVariant="LARGE"
          willUseSubText={false}
        />
      </SelectGroup>
    </Wrapper>
  );
}

export { MotorSearch };
