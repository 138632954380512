import type { IncomingHttpHeaders } from 'http';
import type { AxiosResponse } from 'axios';
import getConfig from 'next/config';

import { donedealServicesGateway } from 'api/api-utils/commonApiUtils';
import type {
  IGetAdsC2BRequest,
  IGetAdsC2BResponse,
  IGetAdsRequest,
  IGetAdsResponseV2,
  IGetFiltersResponse,
} from 'api/types/searchPageApiTypes';
import {
  IGetAdsResponse,
  IGetSearchFilterMakeModel,
  IGetSection,
} from 'api/types/searchPageApiTypes';
import { ENVIRONMENT_NAME } from 'utils';
import axios from 'axios';
import type { GetFiltersWithCountRequest } from 'api/types/searchPageApiTypes';

const {
  publicRuntimeConfig: { API_LISTINGS_TIMEOUT },
} = getConfig();

async function getAds(
  payload: IGetAdsRequest,
  headers: IncomingHttpHeaders = { accept: 'application/json' },
  timeout?: string,
): Promise<AxiosResponse<IGetAdsResponse>> {
  const response: AxiosResponse<IGetAdsResponse> =
    await donedealServicesGateway.post(`/ddapi/v1/search`, payload, {
      headers: {
        ...headers,
        accept: 'application/json',
      },
      timeout: parseInt(timeout || API_LISTINGS_TIMEOUT),
    });

  return response;
}

async function getAdsV2(
  payload: IGetAdsRequest,
  headers: IncomingHttpHeaders = { accept: 'application/json' },
  timeout?: string,
): Promise<AxiosResponse<IGetAdsResponseV2>> {
  const response: AxiosResponse<IGetAdsResponseV2> =
    await donedealServicesGateway.post(`/ddapi/v2/search`, payload, {
      headers: {
        ...headers,
        accept: 'application/json',
      },
      timeout: parseInt(timeout || API_LISTINGS_TIMEOUT),
    });

  return response;
}

async function getAdsC2B(
  payload: IGetAdsC2BRequest,
  headers: IncomingHttpHeaders = { accept: 'application/json' },
  timeout?: string,
): Promise<AxiosResponse<IGetAdsC2BResponse>> {
  if (ENVIRONMENT_NAME === 'dev') {
    const response: AxiosResponse<IGetAdsC2BResponse> = await axios.post(
      'https://dd-dealer-agg.donedeal.dev/v1/search/c2b',
      payload,
      {
        headers: {
          ...headers,
          accept: 'application/json',
          'X-Identity-UserId': 30049,
        },
        timeout: parseInt(timeout || API_LISTINGS_TIMEOUT),
      },
    );
    return response;
  } else {
    const response: AxiosResponse<IGetAdsC2BResponse> =
      await donedealServicesGateway.post('dhapi/v1/search/c2b', payload, {
        headers: {
          ...headers,
          accept: 'application/json',
        },
        timeout: parseInt(timeout || API_LISTINGS_TIMEOUT),
      });
    return response;
  }
}

async function getFiltersWithCount({
  section,
  headers = { accept: 'application/json' },
  payload,
  timeout,
}: GetFiltersWithCountRequest): Promise<AxiosResponse<IGetFiltersResponse>> {
  const response: AxiosResponse<IGetFiltersResponse> =
    await donedealServicesGateway.post(
      `/ddapi/v3/search/filters/${section}`,
      {
        ...payload,
        sections: [section],
      },
      {
        headers: {
          ...headers,
        },
        timeout: parseInt(timeout || API_LISTINGS_TIMEOUT.toString()),
      },
    );
  return response;
}

async function getFiltersDealerShowroom(
  section: string,
  headers: IncomingHttpHeaders = { accept: 'application/json' },
  dealerId: string | null = null,
  timeout?: string,
): Promise<AxiosResponse<IGetFiltersResponse>> {
  const response: AxiosResponse<IGetFiltersResponse> =
    await donedealServicesGateway.get(
      `/ddapi/v1/search/showroom/filters/${section}?dealerId=${dealerId}`,
      {
        headers: {
          ...headers,
        },
        timeout: parseInt(timeout || API_LISTINGS_TIMEOUT),
      },
    );

  return response;
}

async function getFilters(
  section: string,
  headers: IncomingHttpHeaders = { accept: 'application/json' },
  timeout: number = parseInt(API_LISTINGS_TIMEOUT),
): Promise<AxiosResponse<IGetFiltersResponse>> {
  const response: AxiosResponse<IGetFiltersResponse> =
    await donedealServicesGateway.get(`/ddapi/v3/search/filters/${section}`, {
      headers: {
        ...headers,
      },
      timeout: parseInt(timeout || API_LISTINGS_TIMEOUT),
    });

  return response;
}

async function getFiltersC2B(
  headers: IncomingHttpHeaders = { accept: 'application/json' },
): Promise<AxiosResponse<IGetFiltersResponse>> {
  const response: AxiosResponse<IGetFiltersResponse> =
    await donedealServicesGateway.get('ddapi/v1/search/filters/cars-c2b', {
      headers: {
        ...headers,
        accept: 'application/json',
      },
      timeout: parseInt(API_LISTINGS_TIMEOUT),
    });
  return response;
}

async function getModelByMake(
  section: string,
  make: string,
  headers: IncomingHttpHeaders = { accept: 'application/json' },
  dealerId: string | null = null,
  timeout?: string,
): Promise<AxiosResponse<IGetSearchFilterMakeModel>> {
  const response: AxiosResponse<IGetSearchFilterMakeModel> =
    await donedealServicesGateway.get(
      dealerId
        ? `/ddapi/v1/search/showroom/filters/${section}/${make}?dealerId=${dealerId}`
        : `/ddapi/v1/search/filters/${section}/${make}`,
      {
        headers: {
          ...headers,
          accept: 'application/json',
        },
        timeout: parseInt(timeout || API_LISTINGS_TIMEOUT),
      },
    );
  return response;
}

async function getSections(
  section: string = 'all',
  payload: IGetAdsRequest,
  timeout?: string,
): Promise<AxiosResponse<IGetSection>> {
  const response: AxiosResponse<IGetSection> =
    await donedealServicesGateway.post(
      `/ddapi/v1/search/sections/${section}`,
      payload,
      {
        headers: {
          accept: 'application/json',
        },
        timeout: parseInt(timeout || API_LISTINGS_TIMEOUT),
      },
    );

  return response;
}

export const searchPageApi = {
  getAds,
  getAdsV2,
  getAdsC2B,
  getFilters,
  getFiltersWithCount,
  getFiltersC2B,
  getFiltersDealerShowroom,
  getModelByMake,
  getSections,
};
