import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useOnUpdateOnly } from 'hooks/UseOnUpdateOnly';
import { Button } from 'components/Toolkit/Button/Button';
import { ListItem } from 'components/Toolkit/Inputs/ListItem';
import { StickyFooter } from 'components/Toolkit/StickyFooter/StickyFooter';
import { Columns } from 'components/Toolkit/Columns/Columns';

export type TListItem = {
  value: string;
  displayName: string;
  group?: string;
};

type ListItemVariant = {
  groupDisplayName: string;
  group: string;
  values: { value: string; displayName: string }[];
};

export interface CustomMultiSelectProps {
  listData: (TListItem | ListItemVariant)[];
  preselectedOption?: TListItem[];
  defaultPreselect: TListItem;
  onApply: (t: TListItem[]) => void;
  onCallback: (t: TListItem[]) => void;
  onReset?: () => void;
  callReset?: boolean;
  setCallReset?: (t: boolean) => void;
  callApply?: boolean;
  setCallApply?: (t: boolean) => void;
  hasButtons?: boolean;
}

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.WHITE};
`;

const List = styled.ul`
  ${({ theme }) => theme.fontSize.M14};
  padding: 0 0 1px 0;
`;

const ListHeadingItem = styled.li`
  ${({ theme }) => theme.fontSize.S12};
  padding: ${({ theme }) => `0 ${theme.spacing.M16}`};
  color: ${({ theme }) => theme.colors.GREY_DARK};
  margin: -1px 0;
`;

function CustomMultiSelect({
  listData,
  preselectedOption,
  defaultPreselect,
  onApply,
  onCallback,
  onReset,
  callReset = false,
  setCallReset,
  callApply = false,
  setCallApply,
  hasButtons = true,
}: CustomMultiSelectProps) {
  const defaultItem = listData[0];
  const modifiedListData = listData.slice(1);
  const [isDefaultReset, setIsDefaultReset] = useState<boolean>(false);
  const [isReset, setIsReset] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<TListItem[]>(
    preselectedOption ? [...preselectedOption] : [],
  );

  function setDefault() {
    if ('displayName' in defaultItem) {
      handleListItemClick(true, defaultItem, true);
    } else {
      handleListItemClick(true, defaultItem.values[0], true);
    }
  }

  function handleListItemClick(
    isChecked: boolean,
    item: TListItem,
    isDefault?: boolean,
  ) {
    if (isDefault && isChecked) {
      setSelectedValues([item]);
      setIsReset(true);
    } else {
      setIsDefaultReset(true);
      if (isChecked) {
        setSelectedValues((prev) => [
          ...prev.filter((val) => val.value !== defaultPreselect.value),
          item,
        ]);
      } else {
        const newSelectedValues = selectedValues.filter(
          (val) => val.value !== item.value,
        );
        if (!newSelectedValues.length) {
          // Set default if no values selected
          setDefault();
        } else {
          setSelectedValues(newSelectedValues);
        }
      }
    }
  }

  useEffect(() => {
    if (callReset) {
      handleReset();
      setCallReset && setCallReset(false);
    } else if (callApply) {
      handleApply();
      setCallApply && setCallApply(false);
    }
  }, [callReset, callApply]);

  useEffect(() => {
    if (isDefaultReset) {
      setIsDefaultReset(false);
    }
  }, [isDefaultReset]);

  useEffect(() => {
    if (isReset) {
      setIsReset(false);
    }
  }, [isReset]);

  useEffect(() => {
    if (isDefaultReset) {
      setIsDefaultReset(false);
    }
  }, [isDefaultReset]);

  useOnUpdateOnly(() => {
    onCallback(selectedValues);
  }, [selectedValues]);

  function handleReset() {
    setIsReset(true);
    setIsDefaultReset(true);
    setSelectedValues([]);
    setDefault();
    onReset && onReset();
  }

  function handleApply() {
    if (selectedValues) {
      onApply(selectedValues);
    }
  }

  return (
    <Container>
      <List>
        {'displayName' in defaultItem ? (
          <ListItem
            isDefault
            clickCallback={(isChecked) =>
              handleListItemClick(isChecked, defaultItem, true)
            }
            displayName={defaultItem.displayName}
            isReset={isDefaultReset}
            isPreSelected={
              preselectedOption?.filter(
                (preselect) => defaultItem.value === preselect.value,
              ).length !== 0
            }
          />
        ) : (
          <ListItem
            isDefault
            clickCallback={(isChecked) =>
              handleListItemClick(isChecked, defaultItem.values[0], true)
            }
            displayName={defaultItem.values[0].displayName}
            isReset={isDefaultReset}
            isPreSelected={
              preselectedOption?.filter(
                (preselect) => defaultItem.values[0].value === preselect.value,
              ).length !== 0
            }
          />
        )}
        {modifiedListData.map((item: TListItem | ListItemVariant, index) => {
          return 'displayName' in item ? (
            item.value ? (
              <ListItem
                key={index}
                clickCallback={(isChecked) =>
                  handleListItemClick(isChecked, item)
                }
                displayName={item.displayName}
                isReset={isReset}
                isPreSelected={
                  preselectedOption?.filter(
                    (preselect) => item.value === preselect.value,
                  ).length !== 0
                }
              />
            ) : (
              <ListHeadingItem key={index}>{item.displayName}</ListHeadingItem>
            )
          ) : (
            [
              <ListHeadingItem key={index}>
                {item.groupDisplayName}
              </ListHeadingItem>,
              ...item.values.map((item, index) => (
                <ListItem
                  key={`${index}${index}`}
                  clickCallback={(isChecked) =>
                    handleListItemClick(isChecked, item)
                  }
                  displayName={item.displayName}
                  isReset={isReset}
                  isPreSelected={
                    preselectedOption?.filter(
                      (preselect) => item.value === preselect.value,
                    ).length !== 0
                  }
                />
              )),
            ]
          );
        })}
      </List>
      {hasButtons && (
        <StickyFooter position="sticky">
          <Columns>
            <Button ofType="SECONDARY" onClick={handleReset}>
              Reset
            </Button>
            <Button onClick={handleApply}>Apply</Button>
          </Columns>
        </StickyFooter>
      )}
    </Container>
  );
}

export { CustomMultiSelect };
